import React, { useEffect } from 'react';
import './App.css';

// Declare A-Frame elements for TypeScript
declare global {
  namespace JSX {
    interface IntrinsicElements {
      'a-scene': any;
      'a-entity': any;
      'a-camera': any;
      'a-box': any;  // Add this line
    }
  }
}

// Import A-Frame and AR.js scripts
const aframeScript = document.createElement('script');
aframeScript.src = 'https://aframe.io/releases/1.2.0/aframe.min.js';
document.head.appendChild(aframeScript);

const arjsScript = document.createElement('script');
arjsScript.src = 'https://raw.githack.com/AR-js-org/AR.js/master/aframe/build/aframe-ar.js';
document.head.appendChild(arjsScript);

function App() {
  useEffect(() => {
    // This effect runs once when the component mounts
    // It's a good place to set up any necessary listeners or initializations
  }, []);

  return (
    <div className="App" style={{ margin: 0, overflow: 'hidden' }}>
      <a-scene embedded arjs="sourceType: webcam; debugUIEnabled: false;">
        <a-box
          position="0 0 -2"
          rotation="0 45 0"
          color="#4CC3D9"
          scale="1 1 1"
          animation="property: rotation; to: 0 360 0; loop: true; dur: 10000"
        ></a-box>
        <a-camera></a-camera>
      </a-scene>
    </div>
  );
}

export default App;
